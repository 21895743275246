import React from "react";
// import { graphql, Link } from "gatsby";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
  const faqs = [
    {
      question:
        "I don’t own a business, but am interested in tax planning. How can it benefit an individual?",
      answer: (
        <>
          <p>
            <Link to="/personal-tax-preparation-planning-services/">
              Tax planning
            </Link>{" "}
            isn't just for businesses. There are money-saving tax breaks and tax mitigation strategies that work great for individuals as well. Thoughtful tax planning can:
          </p>
          <ul className="styled-list-plus">
            <li>
              Defer income so you can keep more of your earnings and pay less
              taxes in the future
            </li>
            <li>
              Lower taxes on your income so you can keep more of your earnings
            </li>
            <li>
              Lower taxes on your estate and gifts so your beneficiaries keep
              more
            </li>
            <li>
              Lower taxes on investments and retirement distributions so you
              maintain your lifestyle
            </li>
          </ul>
        </>
      ),
    },
    {
      question:
        "I’m not sure what’s involved in estate planning. What are the steps?",
      answer: (
        <>
          <p>
            <Link to="/estate-trust-planning-services/">Estate planning</Link>{" "}
            can be very simple or very complex, and the steps can vary depending on your individual circumstances. Simple estate planning can include specifying what happens to your assets after you pass away, outlining what happens if you become incapacitated, communicating your final wishes, and appointing guardians over minor children. Complex estate planning can include advanced estate and gift tax mitigation strategies, entity structuring with trusts and family limited partnerships, business succession strategies, asset protection strategies, probate avoidance, life insurance planning, legacy planning, and more. Regardless of the complexity, our estate planning experts can help you develop a plan to protect your heirs, your assets, and your legacy.
          </p>
          
        </>
      ),
    },
    {
      question:
        "I'm considering buying a business but need advice when it comes to the financial part. Can you help?",
      answer: (
        <>
          <p>
            Yes, we certainly can. Our team has years of experience in{" "}
            <Link to="/business-consulting-services/">business consulting</Link>{" "}
            and we’re happy to act as an advisor if you’re considering
            purchasing a business.
          </p>
        </>
      ),
    },
    {
      question:
        'I own a business and feel like it’s "stuck" and not growing. How can you help?',
      answer: (
        <>
          <p>
            Our{" "}
            <Link to="/business-consulting-services/">business consulting</Link>{" "}
            experts can identify areas that are negatively affecting
            profitability and growth. Then we take it a step further by
            developing solutions that are practical and sound.
          </p>
        </>
      ),
    },
    {
      question:
        "I suspect fraud may be taking place within my business. Can you analyze my accounting and financials to look for illegal practices?",
      answer: (
        <>
          <p>
            Yes, we can perform a{" "}
            <Link to="/tax-audit-attestation-services/">forensic audit</Link>{" "}
            aimed at uncovering fraud and provide you with evidence that can
            potentially be used in court.
          </p>
        </>
      ),
    },
    {
      question: "How do I know if I need a wealth manager?",
      answer: (
        <>
          <p>
          Major life events, such as those listed below, are a good indication that you need a financial plan. These changes can alter the trajectory of your life, and our {" "}
            <Link to="/wealth-management-services/">
              wealth management services 
            </Link>
             can help keep you on track.

          </p>
          <ul className="list-decimal pl-6">
            <li>A birth or a death</li>
            <li>A marriage or a divorce</li>
            <li>A new job or impending retirement 
                </li>
          </ul>
        </>
      ),
    },
    {
      question: "What are the benefits of having a CPA do your taxes?",
      answer: (
        <>
          <p>
          Our CPAs are experts in taxation and can handle the most complex tax filings. They consistently monitor tax law changes, regulations, and court rulings and know the strategies that can save you on your taxes. They’ll find deductions and credits you weren’t even aware of and can easily handle complicated returns.
          </p>
          <ul className="styled-list-plus">
            <li>
              <Link to="/business-tax-preparation-services/">
                Business Tax Preparation
              </Link>
            </li>
            <li>
              <Link to="/personal-tax-preparation-planning-services/">
                Personal Tax Preparation
              </Link>
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "What do I do if the IRS wants to audit my tax return?",
      answer: (
        <>
          <p>
            The first thing we tell our clients is not to stress. Expert help is available and at DeBlanc, Murphy & Murphy{" "}
            <Link to="/irs-representation-services/">we’ll represent you</Link>{" "}
            so you don’t even have to speak with the IRS. We do that on your
            behalf.
          </p>
          <p>
            We’ll also help you prepare any documents that the IRS will need for
            the audit. And when it’s all said and done and the IRS has issued
            its findings, we’ll help you appeal if you disagree with the
            results.
          </p>
        </>
      ),
    },
    {
      question: "How do I track my federal refund?",
      answer: (
        <>
          <p>
            Tracking your IRS refund is as simple as going to their website and
            using their{" "}
            <a
              href="https://sa.www4.irs.gov/irfof/lang/en/irfofgetstatus.jsp"
              target="_blank"
              rel="noreferrer"
            >
              Where’s My Refund
            </a>{" "}
            tool.
          </p>
        </>
      ),
    },
    {
      question: "Is crypto a good investment?",
      answer: (
        <>
          <p>
          Cryptocurrency is known for its volatile nature and it does pose a risk for investors. However, it can help you create a diversified portfolio and our team at DeBlanc, Murphy & Murphy can{" "}
            <Link to="/crypto-consulting-services/">counsel you</Link> on how best to incorporate it into your investments.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="FAQs | DeBlanc + Murphy | Washington Metro Area CPA Firm"
        description="Learn more about how DeBlanc + Murphy services can benefit you in our frequently asked questions. Proud to serve the greater Washington, DC, area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-14 pb-18 md:pt-16 md:pb-24">
        <div className="container">
          <div className="mx-auto max-w-[691px]">
            <header className="mb-14 md:mb-18">
              <h1>DeBlanc, Murphy & Murphy FAQs</h1>
            </header>

            <Accordion
              allowZeroExpanded={true}
              className="border-t border-gray-300"
            >
              <div className="space-y-4">
                {faqs.map((faq, i) => {
                  return (
                    <div key={i}>
                      <AccordionItem
                        className="border-b border-gray-300 py-5 md:py-7"
                        uuid={i}
                      >
                        <AccordionItemButton className="flex justify-between space-x-6 focus:outline-none md:space-x-10 lg:space-x-18">
                          <AccordionItemState>
                            {(state) => {
                              return (
                                <>
                                  <div className="mb-0 font-semibold text-typography-heading">
                                    {faq.question}
                                  </div>

                                  <div className="relative flex h-9 min-w-[36px] items-center justify-center rounded-full bg-gradient-to-r from-secondary-500 to-primary-500">
                                    <div className="absolute inset-0 m-px rounded-full bg-[#1D1D1D] opacity-100 transition-opacity duration-300 ease-linear group-hover:opacity-0" />
                                    <i
                                      className={`z-10 text-lg text-primary-500 transition-all duration-300 ease-linear group-hover:text-white ${
                                        state.expanded
                                          ? "fa-light fa-minus"
                                          : "fa-light fa-plus"
                                      }`}
                                    ></i>
                                  </div>
                                </>
                              );
                            }}
                          </AccordionItemState>
                        </AccordionItemButton>
                        <AccordionItemPanel className="animate-fadeIn pt-6 pr-10">
                          {faq.answer}
                        </AccordionItemPanel>
                      </AccordionItem>
                    </div>
                  );
                })}
              </div>
            </Accordion>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

// export const data = graphql`
//   {
//   }
// `;

export default Page;
